import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let AulaLocalShowButton = class AulaLocalShowButton extends Vue {
};
__decorate([
    Prop({ type: Number })
], AulaLocalShowButton.prototype, "propAulaLocal", void 0);
__decorate([
    Prop({ type: Number })
], AulaLocalShowButton.prototype, "propEscolaId", void 0);
AulaLocalShowButton = __decorate([
    Component
], AulaLocalShowButton);
export default AulaLocalShowButton;
