import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { analyticsStore, contratoStore, mainStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import AulaLocalShowButton from '@/components/AulaLocalShowButton.vue';
let Desistencia = class Desistencia extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'contrato',
                text: 'Data da rescisão',
                type: 'timestamp',
                value: 'vigencia_fim',
            },
            {
                show: true,
                table: 'aluno',
                text: 'Aluno',
                type: 'str',
                value: 'aluno_nome',
            },
            {
                show: true,
                table: 'aluno',
                text: 'Responsável',
                type: 'str',
                value: 'responsavel_nome',
            },
            {
                show: true,
                table: 'ultima_atividade',
                text: 'Ultima unidade presente',
                type: '',
                value: 'nome_ultima_atividade_executada',
            },
            {
                show: true,
                table: 'ultima_atividade',
                text: 'Data da ultima unidade presente',
                type: 'timestamp',
                value: 'data_ultima_atividade_executada',
            },
            {
                show: true,
                table: 'ultima_atividade',
                text: 'Professor',
                type: 'str',
                value: 'professor_nome',
            },
            {
                show: true,
                table: 'consultor',
                text: 'Consultor que matriculou',
                type: 'str',
                value: 'consultor_full_name',
                field: 'full_name'
            },
            {
                show: true,
                table: 'cancelado_usuario_efetuou',
                text: 'Usuário que efetivou o cancelamento',
                type: 'str',
                value: 'full_name',
            },
            {
                show: true,
                table: 'rota',
                text: 'Aluno na van',
                type: '',
                value: 'rota_ponto_parada_aluno_id',
            },
            {
                show: true,
                table: 'financeiro_rescisao',
                text: 'Valor da rescisão',
                type: '',
                value: 'rescisao_valor_total',
            },
            {
                show: true,
                table: 'financeiro_rescisao',
                text: 'Total pago da rescisão',
                type: 'currency',
                value: 'rescisao_valor_pago',
            },
            // {
            //   show: true,
            //   table: 'ocorrencia',
            //   text: 'Aluno protestado',
            //   type: 'bool',
            //   value: 'tarefa_status_id',
            // },
            {
                show: true,
                table: 'objecao',
                text: 'Motivo',
                type: '',
                value: 'nome',
            },
            {
                show: true,
                table: 'contrato',
                text: 'Observação',
                type: '',
                value: 'cancelado_motivo',
            },
        ];
        this.selectPeriodDate = null;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'contrato.cancelado_data_hora': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await contratoStore.getCadernoDesistenciaQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsCadernoDesistencia({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        if (this.userProfile.company.escola_id === 1) {
            this.headers.push({
                show: true,
                table: 'tmp',
                text: 'Local aula',
                type: '',
                value: 'local_aula_id',
            });
        }
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(contratoStore.getCadernoDesistenciaQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
    }
};
Desistencia = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            AulaLocalShowButton,
        },
    })
], Desistencia);
export default Desistencia;
