var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Caderno de desistência")])]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"prop-title":"Data do cancelamento"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar ")])],1)],1),_c('v-row',[(_vm.totals.por_curso)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por curso","prop-items":_vm.totals.por_curso}})],1):_vm._e(),(_vm.totals.por_motivo)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por motivo","prop-items":_vm.totals.por_motivo}})],1):_vm._e()],1),(_vm.totals.por_curso)?_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_desistencias"},scopedSlots:_vm._u([{key:"item.vigencia_fim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vigencia_fim))+" ")]}},{key:"item.data_ultima_atividade_executada",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.rescisao_valor_total",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value))+" ")]}},{key:"item.rescisao_valor_pago",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value))+" ")]}},{key:"item.rota_ponto_parada_aluno_id",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" Sim ")]):_c('span',[_vm._v(" Não ")])]}},{key:"item.tarefa_status_id",fn:function(ref){
var value = ref.value;
return [(parseInt(value) >= 0)?_c('span',[_vm._v(" "+_vm._s(_vm.getStatusOcorrenciaTarefa(value))+" ")]):_c('span',[_vm._v(" Não ")])]}},{key:"item.cancelado_motivo",fn:function(ref){
var value = ref.value;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.local_aula_id",fn:function(ref){
var item = ref.item;
return [_c('AulaLocalShowButton',{staticClass:"ml-2",attrs:{"propAulaLocal":item.local_aula_id,"propEscolaId":_vm.userProfile.company.escola_id}})]}}],null,true)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }